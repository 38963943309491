import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"IBM_Plex_Mono\",\"arguments\":[{\"weight\":[\"100\",\"300\",\"500\",\"700\"],\"display\":\"swap\",\"style\":\"normal\",\"subsets\":[\"latin\"]}],\"variableName\":\"ibmPlexMono\"}");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/node_modules/react-fast-marquee/dist/index.js");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/Footer/Footer.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/section/Section.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/Blogs/Blogs.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/LargeTitle/LargeTitle.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/Card/Card.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/Header/Header.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/OvalButton/OvalButton.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/Logo/Logo.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/Projects/Projects.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/ProjectCard/ProjectCard.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/JoinUs/JoinUs.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/OpenSource/OpenSource.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/SimpleLink/index.ts");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/StackedButton/StackedButton.tsx");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/TeamFlippableCard/index.ts");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/ui/styles/index.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/ui/components/Paragraph/Paragraph.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/Team/Team.module.css");
import(/* webpackMode: "eager" */ "/var/lib/gitlab-runner/builds/xzyFzdRx/0/Web/react-boys-microsite/src/modules/home/components/Technologies/Technologies.module.css")